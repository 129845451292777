module.exports = ({
    
    'SM310TT001': '権限一覧',
    'SM310H001': '権限',
    'SM310H002': 'カテゴリー',
    'SM310H003': '詳細',
    'SM310H004': 'Audit',
    'SM310P001': '権限を入力してください',
    'SM310OPT001': 'すべて',
    'SM310OPT002': '有効',
    'SM310OPT003': '無効',
    'SM310L001': '権限',
    'SM310L002': 'カテゴリー',
    'SM310L003': 'ステータス',

    "TYPE.SUCCESS": "SUCCESS",
    "SM310.COMMSG001": "{type}が保存されました。",
    "SM310.LABEL.SEARCH.STATUS":"ステータス",
    
    "SM310.LABEL.BUTTON.SEARCH":"検索",
    "SM310.LABEL.BUTTON.CLEAR":"条件クリア",
    "SM310.LABEL.BUTTON.SAVE":"保存",

    'SM311PTT001': '{privilege}の編集履歴',
    'SM311PH001': 'No.',
    'SM311PH002': '権限',
    'SM311PH003': 'カテゴリー',
    'SM311PH004': '詳細',
    'SM311PH005': '有効',
    'SM311PH006': '更新者',
    'SM311PH007': '更新日時',

    "PRIVILEGE.AUDIT.DISPLAY": "件表示",

});