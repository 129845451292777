module.exports = ({
    "TITLE.CREATE": "試験を新規作成",
    "TITLE.USERS" : "ユーザ一覧",
    "SM201.DUPLICATE.ID": "この試験IDはすでに存在します。",
    "CDMS910MSG002" : "パスワードを初期化しました。\n新しいパスワードをメールに送信しました。",
    "SM800MSG001": "{user_id}を削除します。\nよろしいですか。",
    "SM800MSG002": "{user_id}を回復します。\nよろしいですか。",
    "SM802MSG007": "ユーザーが保存されました。",
    "SM800MSG005": "このユーザーは試験に設定されているため、削除できません。",

    "EMAIL.LABEL": "メール",
    "EMAIL.PLACEHOLDER": "メールを入力してくだい",
    "NAME.LABEL": "氏名",
    "NAME.PLACEHOLDER": "氏名を入力してくだい",
    "BUTTON.CLEAR.TEXT": "条件クリア",
    "BUTTON.SEARCH.TEXT": "検索",
    "BUTTON.ADD.USER.TEXT": "新規作成",
    "DISPLAY.TEXT": "件表示",

    "HEADER.NO": "No.",
    "HEADER.ID": "ID",
    "HEADER.EMAIL": "メール",
    "HEADER.NAME": "氏名",
    "HEADER.DELETE": "削除",
    "HEADER.RESET.PW": "パスワード初期化",
    "HEADER.AUDIT": "Audit",

    "CONFIRM.MODAL.TITLE": "確認",
    "CONFIRM.MODAL.MESSAGE": "パスワードを初期化します。\nよろしいですか。",

    "STUDY.AUDIT.LIST": "の編集履歴",
    "STUDY.AUDIT.DISPLAY": "件表示",
    "STUDY.AUDIT.INDEX": "No.",
    "STUDY.AUDIT.PROTOCOLNO": "プロトコルNo.",
    "STUDY.AUDIT.ALIAS": "Alias",
    "STUDY.AUDIT.STUDY.NAME": "試験名",
    "STUDY.AUDIT.CDMS": "CDMS",
    "STUDY.AUDIT.IWRS": "IWRS",
    "STUDY.AUDIT.PRO": "PRO",
    "STUDY.AUDIT.ECONSENT": "eConsent",
    "SM203PH008":"ステータス",
    "STUDY.AUDIT.UPDATEUSER": "更新者",
    "STUDY.AUDIT.UPDATETIME": "更新日時",

    "SA.USER.AUDIT.LIST": "の編集履歴",
    "SA.USER.AUDIT.DISPLAY": "件表示",
    "SA.USER.AUDIT.INDEX": "No.",
    "SA.USER.AUDIT.EMAIL": "メール",
    "SA.USER.AUDIT.USERNAME": "氏名",
    "SA.USER.AUDIT.RESET.PASSWORD": "パスワード初期化",
    "SA.USER.AUDIT.DELETE.FLAG": "削除",
    "SA.USER.AUDIT.UPDATEUSER": "更新者",
    "SA.USER.AUDIT.UPDATETIME": "更新日時",
    "SM810TT001": "保守のお知らせ",

    "SM200LB004" : "ステータス",

    "SM200OPT001": "クローズ",
    "SM200OPT002": "オープン",

    "SM200H006" : "ステータス",

    "SM202LB006":"オープン",
    "SM202LB007":"クローズ",

    "SM202BTN001":"オープン",
    "SM202BTN002":"クローズ",

    "SM202MSG001": "試験をクローズしてもよろしいですか？",
    "SM202MSG002": "試験をオープンしてもよろしいですか？",
    
});