
module.exports = ({
"SM210.TITLE":"EDC設定",
"TYPE.SUCCESS": "Success",
"SM210COMMSG005":"{item}を入力してください。",
"SM210MSG007":"EDC設定が保存されました。",
"SM210MSG003":"整数値を入力してください。",
"COMMSG002":"エラーが発生しました。<br/> 管理者に連絡してください。",
"SM210.PLACEHOLDER.LEVEL":"レベル",
"SM210.TEXT.SAVE":"保存",
"SM210.TEXT.YES":"有り",
"SM210.TEXT.NO":"無し",
"SM210.TEXT.ETRAINING":"e-Training",
"SM210.TEXT.SITECODEMAXDIGIT":"Digits per site code",
"SM210.OPTION.SITECODEMAXDIGIT1":"No restriction",
"SM210.TEXT.SCREENNUMREGEX":"Screening number",
"SM210.TEXT.ENROLLMENT_NUMBER":"Enrollment number",

"SM210.OPTION.AUTO_INPUT":"Auto input",
"SM210.OPTION.USER_INPUT":"User input",

"SM210.TEXT.CONTRACTLEVEL1":"Setting levels on the no. of",
"SM210.TEXT.CONTRACTLEVEL2":"contract cases",
"SM210.OPTION.CONTRACTLEVEL0":"No limitation",
"SM210.OPTION.CONTRACTLEVEL1":"Study",
"SM210.OPTION.CONTRACTLEVEL2":"Site",

"SM210.NAME.CONTRACTLEVEL":"レベル",

"SM210.TEXT.AUTO_LOGOUT_TIME":"Automatic logout time",
"SM210.TEXT.UNI_TIME":"minutes",

"SM210.ITEM.MSG.AUTO_LOGOUT_TIME":"Automatic logout time",
//AUDIT
"SM210.AUDIT.TITLE":"の編集履歴",
"SM210.AUDIT.TABLE.HEADER.NO": "No.",
"SM210.AUDIT.TABLE.HEADER.UPD_USER":"更新者",
"SM210.AUDIT.TABLE.HEADER.UPD_DATE": "更新日時",

});