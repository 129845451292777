module.exports = ({
    "SM300.TITLE":"ロール一覧",
    "TYPE.SUCCESS": "SUCCESS",
    "SM300.COMMSG001": "{type}が保存されました。",
    "SM300.PLACEHOLDER.SEARCH.NAME":"ロールを入力してください",
    "SM300.PLACEHOLDER.SEARCH.DESCRIPTION":"説明を入力してください",

    "SM300.LABEL.SEARCH.NAME":"ロール",
    "SM300.LABEL.SEARCH.DESCRIPTION":"説明",
    "SM300.LABEL.SEARCH.STATUS":"ステータス",

    "SM300.LABEL.BUTTON.SEARCH":"検索",
    "SM300.LABEL.BUTTON.CLEAR":"条件クリア",

    "SM300.TABLE.HEAD_STATUS":"有効",
    "SM300.TABLE.HEAD_NAME":"ロール（※）",
    "SM300.TABLE.HEAD_DESCRIPTION":"説明（※）",
    "SM300.TABLE.HEAD_AUDIT":"Audit",

    "SM300.LABEL.BUTTON.SAVE":"保存",
    "SM300.LABEL.BUTTON.IMPORT":"取り込み",
    "SM300.LABEL.BUTTON.EXPORT":"ダウンロード",

    "SM300.OPTION.STATUS.ALL":"すべて",
    "SM300.OPTION.STATUS.ENABLE":"有効",
    "SM300.OPTION.STATUS.DISABLE":"無効",

    "SM300.NOTE":"※は必須項目です。",
});