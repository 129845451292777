module.exports = ({

    'SM910P001': 'コード',
    'SM910MSG001': 'ARMコードを入力してください。',
    'SM910MSG002': 'ARMを削除すると全ての割付結果が削除されます。\nよろしいですか。',
    'SM910MSG003': 'ARMを復元します。よろしいですか。',
    'SM910MSG004': 'ARMは100文字以下で設定してください。',
    'SM910MSG005': '入力した{item}がすでに使用されています。',
    'SM910MSG006': '入力したデータが正しくありません。',
    'SM910MSG007': '変更されたデータがないため、保存されません。',
    'SM910MSG008': '{type}が保存されました',
    'SM910MSG009': '取り込みが完了しました。',

    //AUDIT
    'SM910PH001': 'No.',
    'SM910PH002': '割付群',
    'SM910PH003': '削除',
    'SM910PH004': '更新者',
    'SM910PH005': '更新日時',
    'SM910PTT001': '{arm}群の編集履歴',

    "SM910.TEXT.BOTTOM": "※は必須項目です。", 

    'SM910H001': 'No.',
    'SM910H002': '割付群（※）',
    'SM910H003': '削除',
    'SM910H004': 'Audit',

})