module.exports = ({
    "SM600.TITLE": "バージョン",
    "SM600.LABEL.UINIT_PAGE": "件表示",

    "SM600.TABLE.HEAD_NO": "No.",
    "SM600.TABLE.CRF_VERSION": "CRFバージョン",
    "SM600.TABLE.ORIGINAL_CRF_VERSION": "元のCRFバージョン",
    "SM600.TABLE.CRF_COPY": "CRF複製",
    "SM600.TABLE.ECS_VERSION": "ECSバージョン",
    "SM600.TABLE.ORIGINAL_ECS_VERSION": "元のECSバージョン",
    "SM600.TABLE.ECS_COPY": "ECS複製",
    "SM600.TABLE.CREATE_USER": "作成者",
    "SM600.TABLE.CREATE_DATE": "作成日時",

    "SM600.BTN.NEW": "新規作成",

    "SM601.CHECKBOX.COPY_ECS": "複製",
    "SM601.TITLE": "バージョンの作成",

    "SM601.TEXT.CRF_VERSION": "CRFバージョンを入力してください",
    "SM601.TEXT.ECS_VERSION": "ECSバージョンを入力してください",
    "SM601.BTN.SAVE": "保存",
    "SM601.BTN.CANCEL": "キャンセル",
    "SM601.BTN.REQUIRED.TEXT":"必須",
    "SM601.CHECKBOX.UP_VERSION":"バージョンアップ",
    "COMMSG006.CRF_VERSION": "入力されたCRFバージョンがすでに使用されています。",
    "COMMSG006.ECS_VERSION": "入力されたECSバージョンがすでに使用されています。" ,
    "SM601MSG005": "入力されたCRFバージョンとECSバージョンがすでに連動されています。"
});