module.exports = ({
    "SM920BTN.RESTORE": "回復",
    "SM920BTN.DELETE": "削除",

    "SM920LBL.REQUIED": "※は必須項目です。",
    "SM920LBL.MORE": "More",

    'SM920P001': 'Stratification group no.',
    'SM920P002': 'Stratification no.',
    'SM920P003': 'コード',
    'SM920P004': 'ラベル',
    'SM920MSG001': 'Stratification group no.を入力してください。',
    'SM920MSG002': '入力されたStratification group no.がすでに使用されています。',
    'SM920MSG003': '数字を入力してください。',
    'SM920MSG004': 'Stratification group no.は100文字以下で設定してください。',
    'SM920MSG005': 'Stratification group no.を削除したら全てのStratification no.も削除されます。\nよろしいですか。',
    'SM920MSG006': 'Stratification group no.を回復したら全てのStratification no.も回復されます。\nよろしいですか。',
    'SM920MSG007': 'Stratification no.を入力してください。',
    'SM920MSG008': '入力されたStratification no.がすでに使用されています。',
    'SM920MSG009': '100文字以下を設定してください。',
    'SM920MSG010': 'コードを入力してください。',
    'SM920MSG011': '入力されたコードがすでに使用されています。',
    'SM920MSG012': 'コードは100文字以下で設定してください。',
    'SM920MSG013': 'ラベルを入力してください。',
    'SM920MSG014': 'ラベルは256文字以下で設定してください。',
    'SM920MSG015': 'Stratification no.を削除します。\nよろしいですか。',
    'SM920MSG016': '全てのStratification no.を削除したら、Stratification group no.も削除されます。\nよろしいですか。',
    'SM920MSG017': 'Stratification no.を回復します。\nよろしいですか。',
    'SM920MSG018': 'データがありません',
    'SM920MSG019': '入力したデータが正しくありません。',
    'SM920MSG020': '変更されたデータがないため、保存されません。',

    'SM920H001': 'Stratification group no.',
    'SM920H002': '操作',
    'SM920H003': 'Stratification no.',
    'SM920H004': 'コード ',
    'SM920H005': 'ラベル',
    'SM920H006': 'Stratification Group',
    'SM920H007': 'ラベル',
    'SM920TT001': 'Static Stratification',
    'SM920TT002': 'Stratification',
    'SM920TT003': 'Stratification Information',

    "SM920PH001": "No.",
    "SM920PH002": "Stratification group no.",
    "SM920PH003": "削除",
    "SM920PH004": "更新者",
    "SM920PH005": "更新日時",

    "SM920PH006": "No.",
    "SM920PH007": "Stratification no.",
    "SM920PH008": "コード",
    "SM920PH009": "ラベル",
    "SM920PH010": "更新者",
    "SM920PH011": "更新日時",

    "SM920PTT001": "Stratification group {groupNo}の編集履歴",
    "SM920PTT002": "Stratification {groupNo}の編集履歴",
});