module.exports = ({
    "TITLE.CREATE": "Create a new exam",
    "SM201.DUPLICATE.ID": "この試験IDはすでに存在します。",
    "SM810TT001": "Notification",

    "SM200LB004" : "Status",

    "SM200OPT001": "Close",
    "SM200OPT002": "Open",

    "SM200H006" : "Status",

    "SM202LB006" : "Open",
    "SM202LB007" : "Close",

    "SM202BTN001": "Open",
    "SM202BTN002": "Close",

    "SM202MSG001": "Are you sure you want to close study?",
    "SM202MSG002": "Are you sure you want to open study?",

    "SM203PH008":"Status"

});
