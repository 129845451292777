module.exports = ({
    "SM220.TITLE": "e-Training",
    "SM220.TEXTID": "グループID",
    "SM220.LABEL": "資料",
    "SM220.CHECKBOX.DELETE": "削除されたものを表示",
    "SM220.CLEAR": "条件クリア",
    "SM220.SEARCH": "検索",
    "SM220.DELETE": "削除",
    "SM220.CONFIRM": "確認",
    "SM220.CONFIRM.NO": "いいえ",
    "SM220.CONFIRM.YES": "はい",
    "SM220.CONFIRM.ISITOK": "よろしいですか。",
    "SM220.UPLOAD": "アップロード",
    "SM220.AUDIT": "Audit",
    "SM220.CANCEL": "削除",
    "SM220.PRERVATION": "保存",
    "SM220.NAME": "資料名",
    "SM220.DESCRIPTION": "資料の説明",
    "SM220.UPDATEAT": "更新日",
    "SM220.BROWSE": "閲覧",
    "SM220.DOWNLOAD": "ダウンロード",
    "SM220.MODAL.BROWSEFILE": "選択",
    "SM220.MODAL.CLOSE": "キャンセル",
    "SM220.MODAL.UPLOAD.TITLE": "資料のアップロード",
    "SM220.MODAL.UPLOAD.LABEL": "ファイル",
    "SM220.MODAL.UPLOAD.PLACEHOLDER": "PDFファイルを選択してください",
    "SM220.MODAL.MESSAGE.PDF": "PDFファイルのみを選択してください。",
    "SM220.LABEL.PLACEHOLDER": "資料を入力してください",
    "SM220.LABEL.REQUIRED": "必須",
    "SM220.ERROR": "エラーが発生しました。",
    "SM220.LABEL.UINIT_PAGE": "件表示",

    // message
    "SM220.MESSAGE.SM220MSG001": "を削除します。",
    "SM220.MESSAGE.SM220MSG002": "を回復します。",
    "SM220.MESSAGE.COMMSG013": "ファイルのサイズが大きすぎます。",
    "SM220.MESSAGE.COMMSG013.MAX": "100MB以下のファイルを選択してください。",
    "SM220.MESSAGE.SM220MSG008": "すでに登録されている資料と同一名称の資料はアップロードできません。",
    "SM220.MESSAGE.SM220MSG007": "ファイル名称は80文字以下を設定してください。",
    "SM220.MESSAGE.SM220MSG005": "取り込みが完了しました。",
    "SM220.MESSAGE.SM220MSG004": "入力したデータが破棄されます。よろしいでしょうか。",
    "SM220.MESSAGE.SM220MSG003": "e-Trainingが保存されました。",
    "SM220.MESSAGE.SM220MSG011": "PDFファイルを選択してください。",
    "SM220.MESSAGE.REQUIRE": "資料の説明を入力してください。",
    "SM220.MESSAGE.SPECIALCHARACTER": `ファイル名称は記号{\/:*?"<>+|,}以外を設定してください。`,

    // placeholder
    "SM220.PLACEHOLDER.SM220P002": "資料の説明を入力してください",

    "ETRAINING.AUDIT.LIST": "の編集履歴",
    "ETRAINING.AUDIT.DISPLAY": "件表示",
    "ETRAINING.AUDIT.INDEX": "No.",
    "ETRAINING.AUDIT.DESCRIPTION": "資料の説明",
    "ETRAINING.AUDIT.DELETE.FLAG": "削除",
    "ETRAINING.AUDIT.UPDATEUSER": "更新者",
    "ETRAINING.AUDIT.UPDATETIME": "更新日時",
});