module.exports = ({
    'SM310TT001': 'Privilege List',
    'SM310H001': 'Privilege Name',
    'SM310H002': 'Category',
    'SM310H003': 'Description',
    'SM310H004': 'Audit',
    'SM310P001': 'Please enter privilege name',
    'SM310OPT001': 'All',
    'SM310OPT002': 'Enabled',
    'SM310OPT003': 'Disabled',

    "SM310L001": "Privilege Name",
    "SM310L002": "Category",
    "SM310L003": "Status",

    "SM310.LABEL.BUTTON.SEARCH": "Search",
    "SM310.LABEL.BUTTON.CLEAR": "Clear",
    "SM310.LABEL.BUTTON.SAVE": "Save",

    'SM311PTT001': 'Audit Trail - {privilege}',
    'SM311PH001': 'No.',
    'SM311PH002': 'Privilege Name',
    'SM311PH003': 'Category',
    'SM311PH004': 'Description',
    'SM311PH005': 'Enable',

    'SM311PH006': 'Updated User',
    'SM311PH007': 'Updated Time',
});