
module.exports = ({

    //AUDIT
    "SM900.AUDIT.TITLE":"の編集履歴",

    'SM900PLB001': 'No.',
    'SM900PLB002': '割付の種類',
    'SM900PLB003': '更新者',
    'SM900PLB004': '更新日時',
    'SM900PLB005': 'No.',
    'SM900PLB006': '盲検化',
    'SM900PLB007': '更新者',
    'SM900PLB008': '更新日時',
    'SM900PLB009': 'No.',
    'SM900PLB010': 'エマージェンシーキーオープンの設定',
    'SM900PLB011': '更新者',
    'SM900PLB012': '更新日時',
    'SM900PLB013': 'No.',
    'SM900PLB014': 'エマージェンシーキーオープンの種類',
    'SM900PLB015': '更新者',
    'SM900PLB016': '更新日時',
    'SM900PLB017': 'No.',
    'SM900PLB018': 'IP管理',
    'SM900PLB019': '更新者',
    'SM900PLB020': '更新日時',
    'SM900PTT001': '割付の種類の編集履歴',
    'SM900PTT002': '盲検化の編集履歴',
    'SM900PTT003': 'エマージェンシーキーオープンの設定の編集履歴',
    'SM900PTT004': 'エマージェンシーキーオープンの種類の編集履歴',
    'SM900PTT005': 'IP管理の編集履歴',
    
    'SM900MSG001': '{type}が保存されました。',
    'SM900LB001': '割付の種類',
    'SM900LB002': '静的割付',
    'SM900LB003': '動的割付',
    'SM900LB004': '盲検化',
    'SM900LB005': 'あり',
    'SM900LB006': 'なし',
    'SM900LB007': 'エマージェンシーキーオープンの設定',
    'SM900LB008': 'あり',
    'SM900LB009': 'なし',
    'SM900LB010': 'エマージェンシーキーオープンの種類',
    'SM900LB011': '即時開鍵',
    'SM900LB012': '申請/承認',
    'SM900LB013': 'IP管理',

    'SM900LB014': '群',
    'SM900LB015': '群+IP No.',
    'SM900TT001': 'Type',

});