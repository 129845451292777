module.exports = ({
    "SM210.TITLE":"EDC設定",
    "TYPE.SUCCESS": "Success",
    "SM210COMMSG005":"{item}を入力してください。",
    "SM210MSG007":"EDC設定が保存されました。",
    "SM210MSG003":"整数値を入力してください。",
    "SM210COMMSG002":"エラーが発生しました。<br/> 管理者に連絡してください。",
    "SM210.PLACEHOLDER.LEVEL":"レベル",
    "SM210.TEXT.SAVE":"保存",
    "SM210.TEXT.YES":"有り",
    "SM210.TEXT.NO":"無し",
    "SM210.TEXT.ETRAINING":"e-Training",
    "SM210.TEXT.SITECODEMAXDIGIT":"Digits per site code",
    "SM210.OPTION.SITECODEMAXDIGIT1":"No restriction",
    "SM210.TEXT.SCREENNUMREGEX":"Screening number",
    "SM210.TEXT.ENROLLMENT_NUMBER":"Enrollment number",
    
    "SM210.OPTION.AUTO_INPUT":"Auto input",
    "SM210.OPTION.USER_INPUT":"User input",
    
    "SM210.TEXT.CONTRACTLEVEL1":"Setting levels on the no.of",
    "SM210.TEXT.CONTRACTLEVEL2":"contract cases",
    "SM210.OPTION.CONTRACTLEVEL0":"No limitation",
    "SM210.OPTION.CONTRACTLEVEL1":"Study",
    "SM210.OPTION.CONTRACTLEVEL2":"Site",
    
    "SM210.TEXT.AUTO_LOGOUT_TIME":"Auto logout time",
    "SM210.TEXT.UNI_TIME":"minutes",
    
});