module.exports = ({

    'SM910P001': 'Code',
    'SM910MSG001': 'Please enter ARM.',
    'SM910MSG002': 'Are you sure you want to delete this ARM?',
    'SM910MSG003': 'Are you sure you want to restore this ARM ? ',
    'SM910MSG004': 'The length of ARM must be less than or equal to 100 characters.',
    'SM910MSG005': 'The input { item } has already been used.',
    'SM910MSG006': 'You have entered invalid data.Please try again.',
    'SM910MSG007': 'You cannot save because no data change has been made.',
    'SM910MSG008': 'Data has been saved.',
    'SM910MSG009': 'Data has been uploaded successfully.',

    //AUDIT
    'SM910PH001': 'No.',
    'SM910PH002': 'ARM',
    'SM910PH003': 'Delete',
    'SM910PH004': 'Updated User',
    'SM910PH005': 'Updated Time',
    'SM910PTT001': '{arm} Audit',

    "SM910.TEXT.BOTTOM": "※ Required data",

    'SM910H001': 'No.',
    'SM910H002': 'ARM（※）',
    'SM910H003': 'Action',
    'SM910H004': 'Audit',
    
})