module.exports = ({
    'SM810LB001': 'Notification',
    'SM810LB002': 'Start Time',
    'SM810LB003': 'End Time',
    'SM810LB004': 'Content',
    'SM810LB005': 'Search by date',
    'SM810LB006': 'Timezone',

    'SM810H001': 'Notification',
    'SM810H002': 'Start Time',
    'SM810H003': 'End Time',
    'SM810H004': 'Timezone',
    'SM810H005': 'Information',

    'SM810TT001': 'Notification',
    'SM811TT001': 'Create Notification',
    'SM812TT001': 'Edit Notification',
    "SM800TT001": "Study List",
    "SM800TT002": "User Management",

    'SM810OTP001': 'Don’t show this dialog again',

    "DISPLAY.TEXT": "件表示",

    'SM810MSG001': 'End Date must be greater than Start Date.',

    'MSG.CHANGE.DATA': 'Configuration settings has been changed. Please refresh the page.',
})