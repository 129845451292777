module.exports = ({

    'SM920P001': 'Stratification group no.',
    'SM920P002': 'Stratification no.',
    'SM920P003': 'Code',
    'SM920P004': 'Label',
    'SM920MSG001': 'Please enter Stratification group no.',
    'SM920MSG002': 'The input Stratification group no. has already been used.',
    'SM920MSG003': 'Please enter number only.',
    'SM920MSG004': 'The length of Stratification group no. must be less than or equal to 100 characters."',
    'SM920MSG005': 'If you delete Stratification group no., all Stratification no. will also be deleted.\nAre you sure?""',
    'SM920MSG006': 'If you restore Stratification group no., all Stratification no. will also be restored.\nAre you sure?"',
    'SM920MSG007': 'Please enter Stratification no.',
    'SM920MSG008': 'The input Stratification no. has already been used.',
    'SM920MSG009': 'The length of Stratification no. must be less than or equal to 100 characters.',
    'SM920MSG010': 'Please enter code.',
    'SM920MSG011': 'The input code has already been used.',
    'SM920MSG012': 'The length of Code must be less than or equal to 100 characters.',
    'SM920MSG013': 'Please enter label.',
    'SM920MSG014': 'The length of Label must be less than or equal to 256 characters."',
    'SM920MSG015': 'Are you sure you want to delete Stratification no.?""',
    'SM920MSG016': 'If you delete Stratification no., all Stratification group no. will also be deleted.\nAre you sure?""',
    'SM920MSG017': 'Are you sure you want to restore Stratification no.?"',
    'SM920MSG018': 'No data',
    'SM920MSG019': 'You have entered invalid data. Please try again. ',
    'SM920MSG020': 'You cannot save because no data change has been made.',
    'SM920H001': 'Stratification group no.',
    'SM920H002': 'Action',
    'SM920H003': 'Stratification no.',
    'SM920H004': 'Code',
    'SM920H005': 'Label',
    'SM920H006': 'Stratification Group',
    'SM920H007': 'Label',
    'SM920TT001': 'Static Stratification',
    'SM920TT002': 'Stratification',
    'SM920TT003': 'Stratification Information',

    "SM920BTN.RESTORE": "Restore",
    "SM920BTN.DELETE": "Delete",

    "SM920LBL.REQUIED": "※ Required data",
    "SM920LBL.MORE": "More",

    "SM920PH001": "No.",
    "SM920PH002": "Stratification group no.",
    "SM920PH003": "Delete",
    "SM920PH004": "Updated User",
    "SM920PH005": "Updated time",

    "SM920PH006": "No.",
    "SM920PH007": "Stratification no.",
    "SM920PH008": "Code",
    "SM920PH009": "Label",
    "SM920PH010": "Updated User",
    "SM920PH011": "Updated time",

    "SM920PTT001": "Stratification group {groupNo} Audit",
    "SM920PTT002": "Stratification {groupNo} Audit",

});