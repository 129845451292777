module.exports = ({
    "CYCLE.NOTE": "※は必須項目です。",
    "CYCLE.CYCLEID": "Cycle ID",
    "CYCLE.CODE": "コード",
    "CYCLE.REPEAT": "繰返し",
    "CYCLE.SHOW": "表示",
    "CYCLE.LABEL": "ラベル",
    "CYCLE.OPERATION": "操作",
    "CYCLE.CYCLEID.PLACEHOLDER": "IDを入力",
    "CYCLE.CODE.PLACEHOLDER": "コードを入力",
    "CYCLE.CONFIRM.RESTORE": "Cycleを削除したら全てのVisitも削除されます。よろしいですか。",
    "CYCLE.CONFIRM.DELETE": "Cycleを回復したら全てのVisitも回復されます。よろしいですか。",
    "vISIT.CONFIRM.RESTORE": "Visitを回復します。\nよろしいですか。",
    "vISIT.CONFIRM.DELETE": "Visitを削除します。よろしいですか",
});