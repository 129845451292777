module.exports = ({
    "SM700MSG001": "ユーザーを削除します。\nよろしいですか。",
    "SM700MSG002": "ユーザーを回復します。\nよろしいですか。",
    "SM700.SIZE" : "件表示",
    "SM700BUTTON" : {
        "SAVE" : "保存",
        "ADD" : "追加",
    },
    "SM700MSG004" : "Start Dateを入力してください。",
    "SM700MSG005" : "End Dateを入力してください。",

    "SM700.CONFIRM": "確認",
    "CDMS910MSG002" : "パスワードを初期化しました。\n新しいパスワードをメールに送信しました。",
    "SM700P.TITLE" : "ユーザ追加",
    "SM700P.ADD" : "追加",
    "SM700P.CANCEL" : "キャンセル",
    "SM701MSG007" : "開始日と終了日の日付が逆転しております。",
    "SM700MSG006" : "このユーザーはスーパー管理者画面で削除されたため、回復できません。",

    "STUDY.ADMIN.AUDIT.LIST": "の編集履歴",
    "STUDY.ADMIN.AUDIT.DISPLAY": "件表示",

    "STUDY.ADMIN.AUDIT.INDEX": "No.",
    "STUDY.ADMIN.AUDIT.EMAIL": "メール",
    "STUDY.ADMIN.AUDIT.USERNAME": "氏名",
    "STUDY.ADMIN.AUDIT.PRIVILEGE": "Privilege",
    "STUDY.ADMIN.AUDIT.START.DATE": "Start Date",
    "STUDY.ADMIN.AUDIT.END.DATE": "End Date",
    "STUDY.ADMIN.AUDIT.DELETE.FLAG": "削除",
    "STUDY.ADMIN.AUDIT.UPDATEUSER": "更新者",
    "STUDY.ADMIN.AUDIT.UPDATETIME": "更新日時",
});