module.exports = ({
    "CYCLE.NOTE": "※は必須項目です。",
    "CYCLE.CYCLEID": "Cycle ID",
    "CYCLE.VISITID": "VISIT ID",
    "CYCLE.CODE": "コード",
    "CYCLE.CODE.LABEL.ERROR": "Cycleコード",
    "CYCLE.REPEAT": "繰返し",
    "CYCLE.SHOW": "表示",
    "CYCLE.LABEL": "ラベル",
    "CYCLE.OPERATION": "操作",
    "CYCLE.CYCLEID.PLACEHOLDER": "ID",
    "CYCLE.CODE.PLACEHOLDER": "コード",
    "CYCLE.LABEL.PLACEHOLDER": "ラベル",
    "CYCLE.CONFIRM.DELETE": "Cycleを削除したら全てのVisitも削除されます。\nよろしいですか。",
    "CYCLE.CONFIRM.RESTORE": "Cycleを回復したら全てのVisitも回復されます。\nよろしいですか。",
    "VISIT.CONFIRM.RESTORE": "Visitを回復します。\nよろしいですか。",
    "VISIT.CONFIRM.DELETE": "Visitを削除します。\nよろしいですか。",
    "VISIT.CONFIRM.DELETE.CYCLE": "全てのVisitを削除したら、Cycleも削除されます。\nよろしいですか。",
    "VISIT.TEXTID": "ID",
    // "VISIT.TEXTID.PLACEHOLDER": "IDを入力",
    "VISIT.CODE": "VISIT CODE",
    "VISIT.CODE.LABEL.ERROR": "Visitコード",
    "VISIT.LABEL": "VISIT LABEL",
    "CYCLE.VISIT": "Cycle&Visit",
    "CYCLE.DUPLICATE": "入力されたCycle IDがすでに使用されています。",
    "CYCLE.CODE.DUPLICATE": "入力されたCycleコードがすでに使用されています。",
    "VISIT.VISITID.DUPLICATE": "入力されたVisit IDがすでに使用されています。",
    "VISIT.CODE.DUPLICATE": "入力されたVisitコードがすでに使用されています。",
    "CYCLE.CODE.REGEX": "入力したデータが正しくありません。",
    "CYCLE.AUDIT.LIST": "の編集履歴",
    "VISIT.AUDIT.LIST": "の編集履歴",
    "CYCLE.AUDIT.TITLE.NO": "No.",
    "CYCLE.AUDIT.TITLE.CYCLEID": "Cycle ID",
    "CYCLE.AUDIT.TITLE.VISITID": "Visit ID",
    "CYCLE.AUDIT.TITLE.CODE": "コード",
    "CYCLE.AUDIT.TITLE.REPEAT": "繰返し",
    "CYCLE.AUDIT.TITLE.DISPLAY": "表示 ",
    "CYCLE.AUDIT.TITLE.LABEL": "ラベル",
    "CYCLE.AUDIT.TITLE.RESTORE": "削除",
    "CYCLE.AUDIT.TITLE.USERNAME": "更新者",
    "CYCLE.AUDIT.TITLE.UPDATETIME": "更新日付",
    "CYCLE.IMPORT.TITLE": "Excelファイルの取り込み",
    "SM400MSG020":"{n}行目：{item}に入力したデータが正しくありません。",
    "SM400MSG021": "{n}行目：{item}がすでに使用されています。",
    "SM400MSG019": "{n}行目：{item}が必須です。",
    "SM400MSG018": "ファイルの形式が異なります。",
    "SM400MSG024": "{n}行目：{item}の長さが{max_length}以下入力してください。",
    "SM400MSG023": "取り込みが完了しました。",
});