module.exports = ({
    "SM130.TITLE":"パスワード設定", 
    "SM130.PASSWORD.LABEL":"新しいパスワード", 
    "SM130.PASSWORD.PLACEHOLDER":"新しいパスワードを入力してください", 
    "SM130.CONFIRMPASSWORD.LABEL":"新しいパスワードの確認", 
    "SM130.QUESTION.LABEL":"秘密の質問",
    "SM130.ANSWEAR.LABEL":"回答",
    "SM130.ANSWEAR.PLACEHOLDER":"回答を入力してください",
    "SM130.BUTTON.TO.LOGIN":"ログインに戻る",
    "SM130.BUTTON.SUBMIT":"保存",
    "SM130.MESSAGE.SUCCESS":"パスワード設定が完了しました。",
    "SM120.BUTTON.SUBMIT":"ID検索",
    "SM120.MESSAGE.SUCCESS":"ユーザーIDをメールに送信しました。",
    "SM120MSG001":"入力されたメールアドレスは登録されていません。",
    "SM120MSG005":"メールアドレスを入力してください。",
    "SM120MSG004":"秘密の質問に対する回答が異なります。",
    "SM120.LABEL.EMAIL":"メールアドレス",
    "SM120.LABEL.PLACEHOLDER":"メールアドレスを入力してください",
    "SM120.TITLE":"ユーザーIDを忘れた場合",
    "SM110.TITLE":"パスワードを忘れた場合",
    "SM110.BUTTON.SUBMIT":"パスワード初期化",
    "SM110.SUCCESS.TITLE":"パスワードを忘れた場合",
    "SM110.SUCCESS.CONTENT":"新しいパスワードをメールで送信しました。",
    "PWD_CONFIRM_BLANK":"新しいパスワードの確認を入力してください。",
    "NEW_PWD_BLANK":"新しいパスワードを入力してください。",
    "SM130MSG004":"以前に登録されたパスワードと同様のパスワードが設定されています。"
});