module.exports = ({
    'SM900MSG001': 'Data has been saved.',
    'SM900LB001': 'Stratification Type',
    'SM900LB002': 'Static Stratification',
    'SM900LB003': 'Dynamic Stratification',
    'SM900LB004': 'Blind',
    'SM900LB005': 'Yes',
    'SM900LB006': 'No',
    'SM900LB007': 'Emergency Key Open Setting',
    'SM900LB008': 'Yes',
    'SM900LB009': 'No',
    'SM900LB010': 'Emergency Key Open Type',
    'SM900LB011': 'Immediate Key Open',
    'SM900LB012': 'Request/Approve',
    'SM900LB013': 'IP Management',
    'SM900LB014': 'ARM',
    'SM900LB015': 'IP No. ARM IP No.',
    'SM900TT001': 'Type',

    'SM900PLB001': 'No.',
    'SM900PLB002': 'Type of stratification',
    'SM900PLB003': 'Updated User',
    'SM900PLB004': 'Updated Time  ',
    'SM900PLB005': 'No.',
    'SM900PLB006': 'Blind ',
    'SM900PLB007': 'Updated User',
    'SM900PLB008': 'Updated Time  ',
    'SM900PLB009': 'No.',
    'SM900PLB010': 'Emergency Key Open Setting',
    'SM900PLB011': 'Updated User',
    'SM900PLB012': 'Updated Time  ',
    'SM900PLB013': 'No.',
    'SM900PLB014': 'Emergency Key Open Type ',
    'SM900PLB015': 'Updated User',
    'SM900PLB016': 'Updated Time  ',
    'SM900PLB017': 'No.',
    'SM900PLB018': 'IP Management',
    'SM900PLB019': 'Updated User',
    'SM900PLB020': 'Updated Time    ',
    'SM900PTT001': 'Audit Trail - Stratification Type',
    'SM900PTT002': 'Audit Trail - Blind',
    'SM900PTT003': 'Audit Trail - Emergency Key Open Setting"',
    'SM900PTT004': 'Audit Trail - Emergency Key Open Type"',
    'SM900PTT005': 'Audit Trail - IP Management',
})