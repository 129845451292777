module.exports = ({
    
    "SM450.TITLE":"CODE LIST",
    "TYPE.SUCCESS": "Success",
    "SM450.COMMSG007":"Code List設定が保存されました。",
    "SM450COMMSG002":"エラーが発生しました。<br/> 管理者に連絡してください。",
    "SM450COMMSG005":"{type}を入力してください。",

    "SM450.LABEL.UINIT_PAGE":"件表示",
    "SM450.TABLE.HEAD_NO":"No.",
    "SM450.TABLE.HEAD_ID":"ID",
    "SM450.TABLE.HEAD_NAME":"名称",
    "SM450.TABLE.HEAD_UIVALUE":"UI Val.",
    "SM450.TABLE.HEAD_DBVALUE":"DB Val.",
    "SM450.TABLE.HEAD_ITEM_COUNT":"Item Count",
    "SM450.TABLE.HEAD_AUDIT":"Audit",

    "SM450.BTN.NEW":"新規作成",
    "SM450.BTN.UPLOAD":"取り込み",
    "SM450.BTN.DOWNLOAD":"ダウンロード",
    "SM450.BTN.SEARCH":"検索",
    "SM450.BTN.RESET":"条件クリア",
    "SM450.CHECKBOX.DELETE":"削除されたものを表示",

    "SM450.LABEL.ID":"ID",
    "SM450.LABEL.NAME":"名称",
    "SM450.LABEL.UIVALUE":"UI Val.",
    "SM450.LABEL.DBVALUE":"DB Val.",

    "SM450.PLACEHOLDER.ID":"IDを入力してください",
    "SM450.PLACEHOLDER.NAME":"名称を入力してください",
    "SM450.PLACEHOLDER.UIVALUE":"UIVal.を入力してください",
    "SM450.PLACEHOLDER.DBVALUE":"DBVal.を入力してください",

    "SM451.TITLE":"CodeListの作成",
    "SM451.NOTE":"※は必須項目です。",
    "SM451.BTN.SAVE":"保存",
    "SM451.BTN.CANCEL":"キャンセル",
    "SM451.REQUIRE":"必須",
    "SM451.LABEL.NAME":"名称",
    "SM451.TEXT.YES":"YES",
    "SM451.TEXT.NO":"NO",

    "SM451.VALUE_DF.UIVALUE1":"[NotSelected]",
    "SM451.VALUE_DF.DBVALUE1":"EMPTY",

    "SM451.LABEL.UPDATE_USER":"更新者",
    "SM451.LABEL.UPDATE_DATE":"更新日時",
    "SM451.TABLE.HEAD_UIVALUE":"UI Val.（※）",
    "SM451.TABLE.HEAD_DBVALUE":"DB Val.（※）",
    "SM451.TABLE.HEAD_DISPLAY_LABEL1":"ラベル",
    "SM451.TABLE.HEAD_DISPLAY_LABEL2":"非表示",
    "SM451.TABLE.HEAD_DISPLAY_LABEL":"ラベル非表示",
    "SM451.TABLE.HEAD_INPUT_TEXT1":"テキスト",
    "SM451.TABLE.HEAD_INPUT_TEXT2":"入力",
    "SM451.TABLE.HEAD_DELETE":"削除",
   
    "SM451.REQUIRE_DB_VALUE":"DB Val.を入力してください。",
    "SM451.REQUIRE_UI_VALUE":"UI Val.を入力してください。",
    "SM451.DUPLICATEDATA":"入力された{type}がすでに使用されています。",
    "SM451.NUMBER.CODE_LIST_VALUE":"1つ以上のコード値を入力してください。",

    "SM452.TITLE":"CodeListの更新",
    "SM452MSG001":"このCODE LISTはItemに設定されているため削除できません。",
    
    "SM452.MSG.CONIRM.DELETE":"{type}を削除します。\nよろしいですか。",

    "SM454.TITLE":"Excelファイルの取り込み",
    "SM454.LABEL.FILE":"ファイル",
    "SM454.LABEL.REQUIRED":"必須",
    "SM454.PLACEHOLDER.FILE":"ファイルを選択してください",
    "SM454.BTN.BROWER":"選択",
    "SM454.BTN.UPLOAD":"取り込み",
    "SM454.BTN.CANCEL":"キャンセル",
    "SM454.NOTE1":"ファイルの形式が異なります。",
    "SM454.NOTE2":"Excelファイルのみを選択してください。",
    "SM454.UPLOAD.SUCCESS":"取り込みが完了しました。",
    // #51626
    "SM454.MSG.DATA.INVALID": "{n}行目：{item}に入力したデータが正しくありません。",
    // #51644
    "SM451.MSG.DATA.DUPLICATEDATA": "{n}行目：{item}がすでに使用されています。",
    //audit
    "SM453P.AUDIT.TITLE": "の編集履歴",
    });