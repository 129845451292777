module.exports = ({
    'SM810LB001': '保守名',
    'SM810LB002': '開始日',
    'SM810LB003': '終了日',
    'SM810LB004': 'メッセージ',
    'SM810LB005': '日付で検索',
    'SM810LB006': '標準時間帯',

    'SM810H001': '保守名',
    'SM810H002': '開始日',
    'SM810H003': '終了日',
    'SM810H004': '標準時間帯',
    'SM810H005': 'Information',

    'SM810TT001': '保守のお知らせ',
    'SM811TT001': '保守の作成',
    'SM812TT001': '保守の更新',
    "SM800TT002": "ユーザ一覧",
    "SM800TT001": "試験一覧",

    'SM810OTP001': '今後、このダイアログボックスを表示しない',

    "DISPLAY.TEXT": "件表示",

    'SM810MSG001': '開始日と終了日の日付が逆転しております。',

    'MSG.CHANGE.DATA': 'データが変更されました。\nページを更新してください。',
})