module.exports = ({
    "SM300.TITLE":"ロール一覧",
    "TYPE.SUCCESS": "SUCCESS",
    "SM300.COMMSG001": "{type}が保存されました。",
    "SM300.PLACEHOLDER.SEARCH.NAME":"ロールを入力してください",
    "SM300.PLACEHOLDER.SEARCH.DESCRIPTION":"説明を入力してください",

    "SM300.LABEL.SEARCH.NAME":"ロール",
    "SM300.LABEL.SEARCH.DESCRIPTION":"説明",
    "SM300.LABEL.SEARCH.STATUS":"ステータス",

    "SM300.LABEL.BUTTON.SEARCH":"検索",
    "SM300.LABEL.BUTTON.CLEAR":"条件クリア",

    "SM300.TABLE.HEAD_STATUS":"有効",
    "SM300.TABLE.HEAD_NAME":"ロール（※）",
    "SM300.TABLE.HEAD_DESCRIPTION":"説明（※）",
    "SM300.TABLE.HEAD_AUDIT":"Audit",

    "SM300.LABEL.BUTTON.SAVE":"保存",
    "SM300.LABEL.BUTTON.IMPORT":"取り込み",
    "SM300.LABEL.BUTTON.EXPORT":"ダウンロード",

    "SM300.OPTION.STATUS.ALL":"すべて",
    "SM300.OPTION.STATUS.ENABLE":"有効",
    "SM300.OPTION.STATUS.DISABLE":"無効",

    "SM300.NOTE":"※は必須項目です。",

    "UPLOAD.POPUP.TITLE": "Excelファイルの取り込み",
    "UPLOAD.POPUP.REQUIRED":"必須",
    "UPLOAD.POPUP.FILE.BOX": "ファイル",
    "UPLOAD.POPUP.PLACEHOLDER": "ファイルを選択してください",
    "UPLOAD.POPUP.SELECT.FILE": "選択",
    "UPLOAD.POPUP.DO.UPLOAD": "取り込み",
    "UPLOAD.POPUP.DO.CANCEL": "キャンセル",

    "SM300MSG001": "入力されたロールがすでに使用されています。",
    "SM400MSG019": "{n}行目：{item}が必須です。",

    "STUDY.ROLE.AUDIT.LIST": "の編集履歴",
    "STUDY.ROLE.AUDIT.INDEX": "No.",
    "STUDY.ROLE.AUDIT.ENABLE": "有効",
    "STUDY.ROLE.AUDIT.NAME": "ロール",
    "STUDY.ROLE.AUDIT.DESCRIPTION": "説明",
    "STUDY.ROLE.AUDIT.UPDATEUSER": "更新者",
    "STUDY.ROLE.AUDIT.UPDATETIME": "更新日時",
    "SM504MSG006":	"{n}行目：{item}がすでに使用されています。",

    "SM504MSG002": "取り込みが完了しました。",
    "SM504MSG008": "{n}行目：{item}に入力したデータが正しくありません。"
});